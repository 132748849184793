<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="1"></nav-header>

    <div class="app-container networkDetection">
      <div class="box">
        <!-- 步驟 7-8級 -->
        <div class="box-setp" v-if="['7', '8'].includes(testLevel)">
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step1.png" alt="第一步">
            <p class="box-setp-div-p">耳機測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step2.png" alt="第二步">
            <p class="box-setp-div-p">麥克風測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step3.png" alt="第三步">
            <p class="box-setp-div-p">鏡頭測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step4.png" alt="第四步">
            <p class="box-setp-div-p">網速測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/test/steps5.png" alt="第五步">
            <p class="box-setp-div-p">螢幕分享</p>
          </div>
        </div>
        <!-- 步驟 1-6級 -->
        <div class="box-setp" v-if="['1', '2', '3', '4', '5', '6'].includes(testLevel)">
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step1.png" alt="第一步">
            <p class="box-setp-div-p">耳機測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step2.png" alt="第二步">
            <p class="box-setp-div-p">鏡頭測試</p>
          </div>
          <div class="box-setp-arrow active">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrow.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div active">
            <img class="box-setp-div-img" src="@/assets/image/test/step3.png" alt="第三步">
            <p class="box-setp-div-p">網速測試</p>
          </div>
          <div class="box-setp-arrow">
            <i class="box-setp-arrow-left"></i>
            <img class="box-setp-arrow-img" src="@/assets/image/test/arrows.png" alt="">
            <i class="box-setp-arrow-right"></i>
          </div>
          <div class="box-setp-div">
            <img class="box-setp-div-img" src="@/assets/image/test/steps4.png" alt="第四步">
            <p class="box-setp-div-p">螢幕分享</p>
          </div>
        </div>

        <!-- 內容 -->
        <div class="box-content">
          <div class="box-content-left">
            <img src="@/assets/image/test/detection4.png" alt="網速測試">
          </div>
          <div class="box-content-right">
            <h1 class="box-content-right-h1">
              網速測試
            </h1>
            <h2 class="box-content-right-h2">
              為了順利進行考試，您的網速建議達到至少 10 Mbps 以上，以維護您考試的權益
            </h2>
            <h3 class="box-content-right-h3">
              網路測試網址：<em @click="openHref">{{testHref}}</em>
            </h3>
            <h3 class="box-content-right-h3">
              若網速超過 10 Mbps（如下圖範例），請點選<span>「NEXT」</span>。
            </h3>
            <div class="box-content-right-div">
              <img src="@/assets/image/test/rectangle1.png" alt="">
            </div>
            <div class="box-content-right-button">
              <div class="button1" @click="openQuestion">有問題請點我</div>
              <div class="button2" @click="next">
                NEXT
                <img src="@/assets/image/test/icon-next.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <!-- 提醒 -->
        <div class="box-remind">
          <img src="@/assets/image/test/remind.png" alt="提醒">
          提醒：若無法排除問題，請加入line@帳號:@688qwier，由客服為您服務。
        </div>
      </div>
    </div>
    
    <!-- 問題彈窗 -->
    <detection-popup v-model="popupShow" type="network_speed"></detection-popup>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
import DetectionPopup from './components/DetectionPopup.vue'
export default {
  name: 'NetworkDetection',
  components: { DetectionPopup },
  data() {
    return {
      popupShow: false,
      testHref: 'https://www.speedtest.net/',
      // 測驗等級
      testLevel: sessionStorage.getItem('testLevel')
    }
  },
  methods: {
    openHref() {
      window.open(this.testHref)
    },
    // 有問題請點我
    openQuestion() {
      this.popupShow = true
    },
    // 下一步
    next() {
      this.$router.push({
        path: '/screenSharing'
      })
    },
  }
}
</script>

<style lang="less" scoped>
.networkDetection {
  .box {
    margin: 20px auto;
    width: 1320px;
    .box-setp {
      display: flex;
      justify-content: center;
      .box-setp-div {
        text-align: center;
        .box-setp-div-img {
          margin-bottom: 11px;
          width: 60px;
          height: 60px;
        }
        .box-setp-div-p {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          letter-spacing: 0.01em;
          color: #989898;
        }
      }
      .box-setp-arrow {
        margin: 24px 0 0;
        padding: 0 60px;
        position: relative;
        width: 6px;
        height: 8px;
        .box-setp-arrow-img {
          width: 100%;
          height: 100%;
        }
        .box-setp-arrow-left {
          position: absolute;
          top: 6px;
          left: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
        .box-setp-arrow-right {
          position: absolute;
          top: 6px;
          right: 0;
          margin: 0 4px;
          width: 50px;
          height: 0px;
          border: 1px dashed #D3D3D3;
        }
      }
      .active {
        .box-setp-div-p {
          color: #0064D9;
        }
        .box-setp-arrow-left {
          border: 1px dashed #0665D8;
        }
        .box-setp-arrow-right {
          border: 1px dashed #0665D8;
        }
      }
    }

    .box-content {
      margin-top: 20px;
      padding: 50px 60px 50px 140px;
      width: calc(100% - 200px);
      height: 500px;
      min-height: calc(100vh - 444px);
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      border-radius: 8px;
      display: flex;
      align-items: center;
      .box-content-left {
        margin-right: 82px;
        width: 438px;
        height: 320px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .box-content-right {
        width: calc(100% - 520px);
        height: 100%;
        .box-content-right-h1 {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 40px;
          color: #333333;
        }
        .box-content-right-h2 {
          margin: 20px 0 14px;
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 32px;
          color: #333333;
        }
        .box-content-right-h3 {
          font-family: "Microsoft JhengHei","微軟正黑體";
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          color: #686A6F;
          em {
            cursor: pointer;
            font-style: normal;
            color: #0C4FA2;
          }
          span {
            color: #0C4FA2;
          }
        }
        .box-content-right-div {
          margin: 20px 0 40px;
          width: 270px;
          height: 150px;
          border-radius: 8px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .box-content-right-button {
          user-select: none;
          display: flex;
          align-items: center;
          .button1 {
            cursor: pointer;
            margin-right: 22px;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #FFFFFF;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #0C4FA2;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .button2 {
            cursor: pointer;
            width: 200px;
            height: 58px;
            border: 1px solid #0C4FA2;
            border-radius: 8px;
            background: #0C4FA2;
            box-shadow: 0px 4px 8px rgba(0, 73, 207, 0.3);
            border-radius: 8px;
            font-family: "Microsoft JhengHei","微軟正黑體";
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              margin-left: 6px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }

    .box-remind {
      margin-top: 20px;
      width: 100%;
      height: 48px;
      background: #FDF3EC;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #DA6B29;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>